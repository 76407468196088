import debounce from 'debounce';
import { document, window } from '../globals';
import { isBreakpointLG } from '../util/sizes';

const stickyHeaderObserver = (): void => {
    const triggerElement = document?.querySelector('[data-sticky-trigger]');

    if (!triggerElement) {
        return;
    }

    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                document?.body?.classList.toggle('has-sticky', entry.intersectionRatio < 1);
            });
        },
        { threshold: [1] },
    );

    if (!isBreakpointLG()) {
        observer.observe(triggerElement);
    }

    window?.addEventListener('resize', debounce(() => {
        if (!isBreakpointLG()) {
            document?.body?.classList.add('has-sticky');
            observer.observe(triggerElement);
        } else {
            observer.unobserve(triggerElement);
            document?.body?.classList.remove('has-sticky');
        }
    }, 1000));
};

export default stickyHeaderObserver;
