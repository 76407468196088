type FlagAbTest = {
    [variation: string]: string;
    flagKey: string;
    variationA: string;
    variationB: string;
};

export const pdpMoveDeliveryStatus: FlagAbTest = {
    flagKey: 'b4-pdp-move-delivery-date',
    variationA: 'original',
    variationB: 'close',
};

export const cartDeliveryFrom: FlagAbTest = {
    flagKey: 'b4-cart-from-delivery',
    variationA: 'with',
    variationB: 'without',
};

export const B4HeaderMobileVersionWithContext: FlagAbTest = {
    flagKey: 'b4-header-mobile-version-with-context',
    variationA: 'default',
    variationB: 'mobile',
};
