import { document } from '../globals';
import { isBreakpointLG } from '../util/sizes';

export default class UspScroller {
    public static init(): void {
        UspScroller.activateUspScroller();
    }

    private static activateUspScroller(): void {
        const uspHeader = document?.querySelector('.js-usp-banner');
        const uspItems = document?.querySelectorAll('.js-usp-banner-main-item');

        if (!(uspItems instanceof NodeList) || !(uspHeader instanceof HTMLElement)) {
            return;
        }

        let index = 0;
        const rotate = () => {
            if (uspHeader.classList.contains('ysp-collapse--out') || isBreakpointLG()) {
                return;
            }

            if (index >= uspItems.length) {
                index = 0;
            }

            Array.from(uspItems).forEach((uspItem, uspIndex) => {
                uspItem.classList.toggle('is-active', uspIndex === index);
            });

            index += 1;
        };

        rotate();
        setInterval(() => {
            rotate();
        }, 4000);
    }
}
